@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Inter:wght@100..900&display=swap');

:root {
  --bs-font-sans-serif: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
}

body {
  height: unset;
  overflow: auto;
}

nav.navbar {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  height: 68px;

  .navbar-brand {
    padding: 0;
    margin: 0;

    img {
      height: 40px;
    }

    span {
      color: var(--bs-gray-300);
    }
  }

  .navbar-nav .nav-item.dropdown .nav-link {
    color: var(--bs-gray-500);
  }
}

main.content {
  margin-top: 68px;
}

body.adjuster.claim main.content {
  @media screen and (min-width: 1400px) {
    margin-top: 165px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    margin-top: 263px;
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    margin-top: 263px;
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    margin-top: 412px;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    margin-top: 412px;
  }
}

.btn.btn-bg-white.btn-outline-primary {
  background-color: var(--bs-white);

  &:hover, &:active, &:focus-visible {
    background-color: var(--bs-primary);
  }
}

.btn.btn-bg-white.btn-outline-success {
  background-color: var(--bs-white);

  &:hover, &:active, &:focus-visible {
    background-color: var(--bs-success);
  }
}

.btn.btn-bg-white.btn-outline-danger {
  background-color: var(--bs-white);

  &:hover, &:active, &:focus-visible {
    background-color: var(--bs-danger);
  }
}

.btn.btn-bg-white.btn-outline-secondary {
  background-color: var(--bs-white);

  &:hover, &:active, &:focus-visible {
    background-color: var(--bs-secondary);
  }
}

.btn.btn-link .feather {
  width: 18px;
  height: 18px;
}

.btn.btn-collapse {
  transition: transform 0.2s ease-in-out;
}

.btn.btn-collapse:not(.collapsed) {
  transform: rotate(-180deg);
}

.btn.btn-collapse svg {
  height: 1.25rem;
  width: 1.25rem;
}

.badge-lg {
  font-size: 90%;
}

h1 {
  font-weight: 700 !important;
}

textarea {
  line-height: 1.35 !important;
}

.card-title {
  font-weight: 600 !important;
}

.form-multiselect {
  
  .selected-list {
  
    .c-btn {
      min-height: unset !important;
      background-clip: padding-box;
      background-color: var(--bs-white);
      border: 1px solid var(--bs-gray-400);
      border-radius: .2rem;
      color: var(--bs-gray-700);
      font-size: .825rem;
      line-height: 1.625;
      padding: .25rem 2.25rem .25rem .7rem !important;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }
    
    .c-list {
      width: unset !important;
      
      .c-token {
        background-color: var(--bs-gray-200);
        padding: 0 0.45rem !important;
        margin-top: 0;
        
        .c-label {
          font-size: unset !important;
          color: unset !important;
        }
      }
    }
    
    .c-remove {
      display: none;
    }
    
    .c-angle-down, .c-angle-up {
      top: unset !important;
      right: 0.75rem !important;
    }
  }
  
  .dropdown-list {
    padding-top: 0.25rem !important;

    .arrow-up {
      display: none;
    }

    .list-area {
      box-shadow: 0 .1rem .2rem rgba(0, 0, 0, .05) !important;
      border: 1px solid var(--bs-gray-400);
      width: fit-content !important;

      ul {
        border-top: 1px solid var(--bs-gray-400);
        padding: 0.5rem 0;
      }

      .pure-checkbox {
        background-color: transparent;
        border: 0;
        clear: both;
        color: var(--bs-gray-700);
        display: block;
        font-weight: 400;
        padding: .35rem 1rem !important;
        text-align: inherit;
        white-space: nowrap;
        width: 100%;

        &.select-all {
          margin: 0.5rem 0;
        }

        &.selected-item label::before {
          color: var(--bs-primary) !important;
          border: 1px solid var(--bs-primary) !important;
        }

        &:hover {
          background-color: var(--bs-gray-100);
        }

        label {
          padding-left: 1.35rem !important;
          color: unset !important;

          &::before {
            width: 12px !important;
            height: 12px !important;
            margin-top: -7px !important;
            color: var(--bs-gray-500) !important;
            border: 1px solid var(--bs-gray-500) !important;
          }

          &::after {
            left: 2px !important;
            margin-top: -4.5px !important;
          }
        }
      }
    }
  }
}

.form-date-range-picker {

  svg, span {
    pointer-events: none;
  }
}

.daterangepicker {

  &.shown {
    display: block;
  }

  &.hidden {
    display: none;
  }

  .calendar-table {

    .active {
      background-color: var(--bs-primary);
      color: var(--bs-white) !important;
    }

    .available:hover {
      border-radius: 4px;
    }

    .start-date {
      border-radius: 4px 0 0 4px;
    }

    .end-date {
      border-radius: 0 4px 4px 0;
    }

    .start-date.end-date {
      border-radius: 4px;
    }
  }

  .card-body {
    padding: 0 !important;

    .ranges ul li {
      padding: 0;
      border-radius: 0 !important;

      button {
        border-radius: 0 !important;

        &:hover {
          border-radius: 0 !important;
        }

        &.active {
          background-color: var(--bs-primary) !important;
        }
      }
    }
  }
}

table {
  font-family: "Inter Tight", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif !important;

  thead tr:last-child > * {
    border-bottom-color: currentColor;
  }

  tfoot tr:first-child > * {
    border-top-color: currentColor;
  }

  tbody tr:last-child td {
    border-bottom-color: initial;
  }

  &.table-explanation {
    font-size: 90%;

    td, th {
      padding-top: 0.125rem;
      padding-bottom: 0.125rem;
    }
  }

  &.table-no-bottom-border {

    tbody:not(:has(~ tfoot)) {
      tr:last-child {
        th, td {
          border: none;
        }
      }
    }

    tfoot tr:last-child {
      th, td {
        border: none;
      }
    }
  }

  &.dataTable.table-sm > thead > tr > th:not(.sorting_disabled) {
    padding-right: 30px;
  }

  &.dataTable.table-sm .sorting::before,
  &.dataTable.table-sm .sorting_asc::before,
  &.dataTable.table-sm .sorting_desc::before {
    right: 1em;
  }

  &.dataTable > thead .sorting::after,
  &.dataTable > thead .sorting::before,
  &.dataTable > thead .sorting_asc::after,
  &.dataTable > thead .sorting_asc::before,
  &.dataTable > thead .sorting_asc_disabled::after,
  & .dataTable > thead .sorting_asc_disabled::before,
  &.dataTable > thead .sorting_desc::after,
  &.dataTable > thead .sorting_desc::before,
  &.dataTable > thead .sorting_desc_disabled::after,
  &.dataTable > thead .sorting_desc_disabled::before {
    bottom: 0.9em !important;
  }

  > :not(caption) > * > * {
    color: unset !important;
  }

  &.dataTable {
    .pe-sort {
      padding-right: 30px;
    }
    .w-icon-1 {
      width: 18px !important;
    }
    .w-icon-2 {
      width: 48px !important;  // two icons 18px wide plus 10px in-between
    }
  }
}

div.dataTables_info {
  line-height: 1.35;
  padding-top: 0.2rem !important;
}

.dataTables_paginate .page-link {
  font-size: unset;
}

.w-35 {
  width: 35% !important;
}

.w-65 {
  width: 65% !important;
}

.ls-2 {
  letter-spacing: 2px;
}

.lh-sm {
  line-height: 1.2 !important;
}

.lh-md {
  line-height: 1.35 !important;
}

.bg-stat {
  background-color: #e0eafc;
}

.bg-gray-100 {
  background-color: var(--bs-gray-100);
}

.bg-gray-200 {
  background-color: var(--bs-gray-200);
}

.border-gray-500 {
  border-color: var(--bs-gray-500) !important;
}

.border-white-35 {
  border-color: rgba(var(--bs-white-rgb), 0.35) !important;
}

.notice-message {
  &:last-of-type {
    margin-bottom: 3rem;
  }

  .alert-message {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.dropzone {
  border: 2px dashed var(--bs-gray-400);

  svg {
    width: 36px;
    height: 36px;
    color: var(--bs-gray-500);
  }

  span {
    color: var(--bs-gray-600);
  }
}

tr.document-list {

  svg {
    height: 22px;
    width: 22px;
  }
}

ul.attachment-list {
  padding: 0;
  margin: 0;
  list-style: none;

  .tag-file {
    list-style: none;
    display: inline-flex;
    align-items: center;
    background-color: var(--bs-gray-200);
    border-radius: 0.3rem;
    line-height: 1.2;
    margin: 0 0.5rem 0.5rem 0;
    padding: 0.5rem 1rem;
    position: relative;
    transition: 0.3s;

    &:hover {
      background-color: var(--bs-gray-400);
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      opacity: .4;
      background-color: #006699;
      border-radius: 0.3rem;
    }

    svg {
      float: left;
      height: 32px;
      width: 32px;
      color: var(--bs-gray-600);
      margin-right: 0.75rem;
    }

    strong {
      color: var(--bs-gray-700);
    }

    small {
      display: block;
      color: var(--bs-gray-600);
    }

    button {
      margin-left: 2rem;
    }
  }

  &.view .tag-file {
    transition: color .15s ease-in-out, background-color .15s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: var(--bs-primary);

      svg {
        color: var(--bs-gray-200);
      }

      strong {
        color: var(--bs-white);
      }

      small {
        color: var(--bs-gray-200);
      }
    }
  }
}

.price-totals-container {

  &.repair-center {
    margin-top: -4rem;
  }
}

ul.price-totals {
  padding: 0;
  margin: 0;
  list-style: none;
  line-height: 1.35;

  .price-item {
    white-space: nowrap;
    margin-left: auto;
    display: flex;
    align-items: flex-end;

    span:first-of-type {
      flex: 1 1 auto;
      text-transform: uppercase;
      padding-right: 1rem;
      color: var(--bs-gray-600);
      text-align: left;
    }

    span:last-of-type {
      flex: 1 1 auto;
      font-weight: 500;
      color: var(--bs-gray-600);
      text-align: right;
    }
  }

  .price-line {
    border-bottom: 1px solid var(--bs-gray-400);
    padding-bottom: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .price-spacer {
    padding: 1rem 0;
  }
}

ul.messages {

  .message-content {

    &.collapsed {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

